<template>
  <div>
    <div class="row">
      <div :class="showDetail? 'col-12':'col-11'">
        <div class="form-group">
          <label for="nom">{{ label }}</label>
          <select
            class="form-control"
            v-select="{placeholder: 'Selectionnez l\'animal'}"
            v-model="animal"
          >
            <option value="" />
            <option
              v-for="(an, index) in sortedAnimaux"
              :value="an.uid"
              :key="index"
            >
              {{ an.name }} [{{ an.codeIso }}]
            </option>
          </select>
        </div>
      </div>
      <div
        class="col-1 view-animal-detail"
        v-if="!showDetail"
      >
        <a
          href="javascript::void(0)"
          v-show="!detail && selectedAnimal !== null"
          @click.prevent="detail = !detail"
        >
          <i class="icofont icofont-eye icofont-3x" />
        </a>
        <a
          href="javascript::void(0)"
          v-show="detail && selectedAnimal !== null"
          @click.prevent="detail = !detail"
        >
          <i class="icofont icofont-eye-blocked icofont-3x" />
        </a>
      </div>
    </div>
    <div
      class="row detail-animal"
      v-if="viewInDetail"
    >
      <div
        class="col-lg-2"
        :style="animalPhotoClass"
      />
      <div class="col-lg-6 animal">
        <h4> {{ name }}</h4>
        <div class="row">
          <div class="col-md-6">
            <p>
              <label> Code ISO</label> {{ selectedAnimal.codeIso }}
            </p>
            <p>
              <label> Espece </label> {{ espece }}
            </p>
            <p>
              <label> Race </label> {{ race }}
            </p>
          </div>
          <div class="col-lg-6">
            <p>
              <label> Date Nais. </label> {{ selectedAnimal.dateNaissance|systemDateTime }}
            </p>
            <p><label> Sexe </label> {{ sexe }}</p>
            <p><label> Robe </label> {{ robe }}</p>
          </div>
        </div>
        <p>
          <label style="width: 100%;"> Signe particuliers</label>
          {{ selectedAnimal.signe }}
        </p>
      </div>
      <div class="col-lg-4">
        <h5>Proprietaire</h5>
        <div class="row proprietaire">
          <div class="col-lg-12">
            <p>
              <label> Nom </label> {{ proprietaire.fullname }}
            </p>

            <p>
              <label> Email </label> {{ proprietaire.email }}
            </p>
          </div>
          <div class="col-lg-12">
            <p>
              <label> Tel.</label> {{ proprietaire.telephone }}
            </p>
            <p> 
              <label>Address</label>
              {{ proprietaire.address }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import { Select } from '../../../directive/select';
const animalPlaceholder = require('../../../assets/img/animalPlaceholder.png')
export default {
    directives: { Select },
    props: {
        showDetail: {type: Boolean, default: false},
        label: {type: String, default: 'Animal'},
        animalUsing: { type: String, default: null }
    },
    data(){
        return {
            animal: null,
            detail: false,
        }
    },
    watch: {
        animal(){
            if(this.animal !== null) this.setSelectedAnimal(this.animal)
        },
        animalUsing(){
          if(this.animalUsing !== null) this.animal = this.animalUsing
        }
    },
    beforeMount(){
      if(this.animalUsing !== null)
        this.animal = this.animalUsing
      else this.setSelectedAnimal(null)
    },
    methods: {
        ...mapMutations({
            setSelectedAnimal: 'identification/SET_SELECTED_ANIMAL'
        })
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            especes: 'identification/especes',
            races: 'identification/races',
            typeRobes: 'identification/typeRobes',
            robes: 'identification/robes',
            proprietaires: 'auth/proprietaires'
        }),
        sortedAnimaux(){
          return [...this.animaux].sort((a,b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        },
        viewInDetail(){
            if(this.animal !== null){
                if(this.showDetail) return true
                return this.detail
            }
            return false
        },
        selectedAnimal(){
            if(this.animal !== null){
                return [...this.animaux].find(item => item.uid === this.animal )
            }
            return null
        },
        proprietaire(){
            if(this.selectedAnimal !== null){
                return [...this.proprietaires].find(item => item.uid === this.selectedAnimal.proprio)
            }
            return null
        },
        name(){
          if(this.selectedAnimal !== null) return this.selectedAnimal.name
          return '-'
        },  
        espece(){
            if(this.selectedAnimal !== null){
                return [...this.especes].find(item => item.uid === this.selectedAnimal.espece).libelle
            }
            return null
        },
        race(){
            if(this.selectedAnimal !== null){
                return [...this.races].find(item => item.uid === this.selectedAnimal.race).libelle
            }
            return null
        },
        robe(){
            if(this.selectedAnimal !== null){
                return [...this.robes].find(item => item.uid === this.selectedAnimal.robe).libelle
            }
            return null
        },
        sexe(){
            if(this.selectedAnimal !== null){
                if(this.selectedAnimal.sexe === 'm') return 'Mâle'
                return 'Femelle'
            }
            return '-'
        },
        photo(){
          if(this.selectedAnimal !== null && this.selectedAnimal.photo !== null) {let p = JSON.parse(this.selectedAnimal.photo); return p.url}
          return animalPlaceholder
        },
        animalPhotoClass(){
          return {
            "background-image": "url("+this.photo+")",
            "background-repeat": "no-repeat",
            "background-size": "contain",
            "background-position": "center center",
            "border-radius": "5px"
          }
        }

    }
}
</script>

<style lang="scss">
.view-animal-detail{
    padding-top: 30px;
}
.detail-animal{
    border: solid 1px rgba(0,0,0,  0.1);
    padding: 20px;
    margin: 10px 0px 30px 0px;
    .animalPhoto{
      width: 100%;
    }
    .animal{
        font-size: 12px;
        label{
            font-size: 12px;
            width: 35%;
        }
    }
    .proprietaire{
        font-size: 12px;
        label{
            font-size: 12px;
            padding-right: 15px;
            width: 35%;
        }
    }
}
</style>